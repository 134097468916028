import 'swiper/swiper-bundle.css';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import '../css/load.scss';

import Swiper from 'swiper/bundle';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

import * as d3 from 'd3v4';

(function ($) {
	const $win = $(window);
	const $doc = $(document);
	const $loader = $('.app-loader');
	const $overlay = $('.app-loader-overlay');
	let newsLoading = false;
	let projectsLoading = false;

	initHeaderMenuToggler();
	initSearchFromIcon();
	initHeaderColorChange();
	initSliderTestimony();
	initSliderTweets();
	initSliderIcons();
	initSliderCustomTabs();
	initPhotoSwipe('.accordion__image');
	initAccordion();
	initPopup();
	initGraphLeverage();
	stopFormSubmission();
	initScrollUpdater();
	initNavScroll();
	initBookingWindow();

	initScrollSection();
	fillscrollline();
	changeStep1();
	changeStep2();

	window.addEventListener('touchmove', handleAjaxLoadMoreNews);
	window.addEventListener('scroll', handleAjaxLoadMoreNews);
	window.addEventListener('touchmove', handleAjaxLoadMoreProjects);
	window.addEventListener('scroll', handleAjaxLoadMoreProjects);

	function initBookingWindow() {
		if (!$('.btn-booking').length) {
			return;
		}

		let bookingIsOpen = false;

		$('.btn-booking').on('click', function (e) {
			e.preventDefault();

			$('.section-side').css('transform', 'translateX(0)');
			addOverlay();
			bookingIsOpen = true;
		});

		$('.section__head-close').on('click', function (e) {
			e.preventDefault();

			$('.section-side').css('transform', 'translateX(-100%)');
			removeOverlay();
			bookingIsOpen = false;
		});

		$doc.mouseup(function (e) {
			let $container = $('.section-side');

			if (bookingIsOpen === true) {
				if (!$container.is(e.target) && $container.has(e.target).length === 0) {
					$container.css('transform', 'translateX(-100%)');
					removeOverlay();
					bookingIsOpen = false;
				}
			}
		});
	}

	function initNavScroll() {
		if (!$('.page-template-home-dark').length) {
			return;
		}

		$('.menu-scroller a').on('click', function (e) {
			e.preventDefault();

			let id = $(this).attr('href');

			$('html, body').animate(
				{
					scrollTop: $(id).offset().top - 140
				},
				1000
			);
		});

		$(window).scroll(function () {
			let elements = ['#wwd', '#wtb', '#hiw', '#projects', '#contacts'];

			elements.forEach((elementId) => {
				let elementTop = $(elementId).offset().top;
				let elementBottom = $(elementId).offset().top + $(elementId).outerHeight();
				let screenTop = $(window).scrollTop();
				let screenBottom = $(window).scrollTop() + $(window).innerHeight();

				if (screenBottom > elementTop && screenTop < elementBottom) {
					$('.menu-scroller')
						.find('a[href="' + elementId + '"]')
						.parent()
						.addClass('current-menu-item');
				} else {
					$('.menu-scroller')
						.find('a[href="' + elementId + '"]')
						.parent()
						.removeClass('current-menu-item');
				}
			});
		});
	}

	function initScrollSection() {
		$('.btn-scroll').on('click', function (e) {
			e.preventDefault();
			const $parent = $(this).parents('section');
			const $nextSection = $parent.next();
			$('html, body').animate(
				{
					scrollTop: $nextSection.offset().top - 100
				},
				2000
			);
		});
	}

	function addLoader() {
		if (!$loader.length) {
			return;
		}

		$loader.addClass('active');
	}

	function removeLoader() {
		if (!$loader.length) {
			return;
		}

		$loader.removeClass('active');
	}

	function addOverlay() {
		if (!$overlay.length) {
			return;
		}

		$('body').addClass('no-scroll');
		$overlay.addClass('active');
	}

	function removeOverlay() {
		if (!$overlay.length) {
			return;
		}

		$('body').removeClass('no-scroll');
		$overlay.removeClass('active');
	}

	function initHeaderMenuToggler() {
		$('.header .header__menu-toggle').on('click', function () {
			$(this).closest('.header__container').toggleClass('menu-open');
		});

		$('.header-dark .header__menu-toggle').on('click', function () {
			$(this).siblings('.header__content').toggleClass('menu-open');
		});

		$('.header-dark .header__menu-close').on('click', function () {
			$(this).parents('.header__content').removeClass('menu-open');
		});
	}

	function initSearchFromIcon() {
		$('.search__btn').on('click', function (e) {
			e.preventDefault();
			$(this).closest('.search-form').toggleClass('active');
		});
	}

	function initHeaderColorChange() {
		let lastScrollTop = 0;
		if (!$('.header').length) {
			return;
		}

		$doc.on('scroll', function () {
			if ($doc.scrollTop() > lastScrollTop && $doc.scrollTop() > 144) {
				$('.header').css('background-color', '#8e743ae0').addClass('scrolled');
			} else if ($doc.scrollTop() < lastScrollTop && $doc.scrollTop() < 144) {
				$('.header').css('background-color', '#ffffff').removeClass('scrolled');
			}

			lastScrollTop = $doc.scrollTop();
		});
	}

	function initSliderTestimony() {
		if (!$('.slider--testimonials .swiper-container').length) {
			return;
		}

		$('.slider--testimonials .swiper-container').each((i, slider) => {
			new Swiper(slider, {
				slidesPerView: 3,
				spaceBetween: 60,
				loop: true,
				autoplay: {
					delay: 2000,
					disableOnInteraction: false
				},
				breakpoints: {
					240: {
						slidesPerView: 1,
						spaceBetween: 30
					},
					413: {
						slidesPerView: 2,
						spaceBetween: 60
					},
					680: {
						slidesPerView: 3,
						spaceBetween: 60
					}
				}
			});
		});
	}

	function initSliderIcons() {
		if (!$('.slider--icons .swiper-container').length) {
			return;
		}

		$('.slider--icons .swiper-container').each((i, slider) => {
			new Swiper(slider, {
				slidesPerView: 2,
				spaceBetween: 60,
				loop: false,
				autoplay: {
					delay: 2000,
					disableOnInteraction: false
				},
				breakpoints: {
					500: {
						autoplay: false,
						slidesPerView: 4,
						spaceBetween: 60
					},
					1240: {
						autoplay: false,
						slidesPerView: 4,
						spaceBetween: 60
					}
				}
			});
		});
	}

	function initSliderTweets() {
		if (!$('.slider--tweets .swiper-container').length) {
			return;
		}

		$('.slider--tweets .swiper-container').each((i, slider) => {
			new Swiper(slider, {
				effect: 'coverflow',
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				loop: true,
				coverflowEffect: {
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: true
				},
				breakpoints: {
					240: {
						slidesPerView: 1,
						spaceBetween: 30
					},
					413: {
						slidesPerView: 2,
						spaceBetween: 60
					},
					680: {
						slidesPerView: 3,
						spaceBetween: 60
					}
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				}
			});
		});
	}

	function initSliderCustomTabs() {
		if (!$('.section-slider').length) {
			return;
		}

		// Tabs
		let $tabContainer = $('.section-slider .section__head');
		let $tabPointer = $tabContainer.find('.section__progress-pointer');

		// Slider
		let $sliderWrapper = $('.slider-custom .slider__wrapper');
		let $slides = $sliderWrapper.find('.slider__slide');

		// Click
		$('.section__trigger').on('click mousedown touchstart', function (e) {
			e.preventDefault();

			// Tabs
			let $newTab = $(this).parent();
			if ($newTab.hasClass('active-tab')) {
				return;
			}

			$tabContainer.find('li').removeClass('active-tab');
			$newTab.addClass('active-tab');
			let newTabLeft = $newTab.position().left;
			let newTabWidth = $newTab.outerWidth();
			let pointerPosition = newTabLeft + newTabWidth / 2;

			$tabPointer.css('left', pointerPosition + 'px');

			// Slider
			let tabIndex = $newTab.data('order');
			let newSlideLeftPosition = $slides.eq(tabIndex).position().left * -1;

			$sliderWrapper.css('transform', 'translateX(' + newSlideLeftPosition + 'px)');
		});

		// Resize
		$(window).on('load resize', function () {
			// Tabs
			let $activeTab = $('.active-tab');
			let activeTabLeft = $activeTab.position().left;
			let activeTabWidth = $activeTab.outerWidth();
			let resizedPointerPosition = activeTabLeft + activeTabWidth / 2;
			$tabPointer.css('left', resizedPointerPosition + 'px');

			// Slider
			let wrapperWidth = $sliderWrapper.outerWidth();
			$slides.css('width', wrapperWidth + 'px');
		});
	}

	function initPhotoSwipe(gallerySelector) {
		if (document.querySelectorAll(gallerySelector) === null) {
			return;
		}
		var parseThumbnailElements = function (el) {
			var thumbElements = el.childNodes,
				numNodes = thumbElements.length,
				items = [],
				figureEl,
				linkEl,
				size,
				item;

			for (var i = 0; i < numNodes; i++) {
				figureEl = thumbElements[i]; // <figure> element

				// include only element nodes
				if (figureEl.nodeType !== 1) {
					continue;
				}

				linkEl = figureEl.children[0]; // <a> element

				size = linkEl.getAttribute('data-size').split('x');

				// create slide object
				item = {
					src: linkEl.getAttribute('href'),
					w: parseInt(size[0], 10),
					h: parseInt(size[1], 10)
				};

				if (figureEl.children.length > 1) {
					// <figcaption> content
					item.title = figureEl.children[1].innerHTML;
				}

				if (linkEl.children.length > 0) {
					// <img> thumbnail element, retrieving thumbnail url
					item.msrc = linkEl.children[0].getAttribute('src');
				}

				item.el = figureEl; // save link to element for getThumbBoundsFn
				items.push(item);
			}

			return items;
		};

		// find nearest parent element
		var closest = function closest(el, fn) {
			return el && (fn(el) ? el : closest(el.parentNode, fn));
		};

		// triggers when user clicks on thumbnail
		var onThumbnailsClick = function (e) {
			e = e || window.event;
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			var eTarget = e.target || e.srcElement;

			// find root element of slide
			var clickedListItem = closest(eTarget, function (el) {
				return el.tagName && el.tagName.toUpperCase() === 'FIGURE';
			});

			if (!clickedListItem) {
				return;
			}

			// find index of clicked item by looping through all child nodes
			// alternatively, you may define index via data- attribute
			var clickedGallery = clickedListItem.parentNode,
				childNodes = clickedListItem.parentNode.childNodes,
				numChildNodes = childNodes.length,
				nodeIndex = 0,
				index;

			for (var i = 0; i < numChildNodes; i++) {
				if (childNodes[i].nodeType !== 1) {
					continue;
				}

				if (childNodes[i] === clickedListItem) {
					index = nodeIndex;
					break;
				}
				nodeIndex++;
			}

			if (index >= 0) {
				// open PhotoSwipe if valid index found
				openPhotoSwipe(index, clickedGallery);
			}
			return false;
		};

		// parse picture index and gallery index from URL (#&pid=1&gid=2)
		var photoswipeParseHash = function () {
			var hash = window.location.hash.substring(1),
				params = {};

			if (hash.length < 5) {
				return params;
			}

			var vars = hash.split('&');
			for (var i = 0; i < vars.length; i++) {
				if (!vars[i]) {
					continue;
				}
				var pair = vars[i].split('=');
				if (pair.length < 2) {
					continue;
				}
				params[pair[0]] = pair[1];
			}

			if (params.gid) {
				params.gid = parseInt(params.gid, 10);
			}

			return params;
		};

		var openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
			var pswpElement = document.querySelectorAll('.pswp')[0],
				gallery,
				options,
				items;

			items = parseThumbnailElements(galleryElement);

			// define options (if needed)
			options = {
				// define gallery index (for URL)
				galleryUID: galleryElement.getAttribute('data-pswp-uid'),

				getThumbBoundsFn: function (index) {
					// See Options -> getThumbBoundsFn section of documentation for more info
					var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
						pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
						rect = thumbnail.getBoundingClientRect();

					return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
				}
			};

			// PhotoSwipe opened from URL
			if (fromURL) {
				if (options.galleryPIDs) {
					// parse real index when custom PIDs are used
					// http://photoswipe.com/documentation/faq.html#custom-pid-in-url
					for (var j = 0; j < items.length; j++) {
						if (items[j].pid == index) {
							options.index = j;
							break;
						}
					}
				} else {
					// in URL indexes start from 1
					options.index = parseInt(index, 10) - 1;
				}
			} else {
				options.index = parseInt(index, 10);
			}

			// exit if index not found
			if (isNaN(options.index)) {
				return;
			}

			if (disableAnimation) {
				options.showAnimationDuration = 0;
			}

			// Pass data to PhotoSwipe and initialize it
			gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
			gallery.init();
		};

		// loop through all gallery elements and bind events
		var galleryElements = document.querySelectorAll(gallerySelector);

		for (var i = 0, l = galleryElements.length; i < l; i++) {
			galleryElements[i].setAttribute('data-pswp-uid', i + 1);
			galleryElements[i].onclick = onThumbnailsClick;
		}

		// Parse URL and open gallery if it contains #&pid=3&gid=1
		var hashData = photoswipeParseHash();
		if (hashData.pid && hashData.gid) {
			openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
		}
	}

	function initAccordion() {
		if (!$('.accordion__head').length) {
			return;
		}

		$('.accordion__head').on('click', function () {
			$(this).parents('.accordion__pannel').siblings().find('.accordion__body').slideUp(280);
			$(this).parents('.accordion__pannel').find('.accordion__body').slideToggle(280);
		});
	}

	function initPopup() {
		//open popup
		$('.download-popup').on('click', function (e) {
			$('.popup').addClass('visible');
		});

		//close popup
		$('.popup').on('click', function (e) {
			if ($(e.target).is('.popup__close') || $(e.target).is('.popup')) {
				e.preventDefault();
				$(this).removeClass('visible');
			}
		});

		//close popup when clicking the esc keyboard button
		$(document).keyup(function (event) {
			if (event.which == '27') {
				$('.popup').removeClass('visible');
			}
		});
	}

	function initGraphLeverage() {
		if (
			!$('body').hasClass('single-app_advisor') &&
			!$('body').hasClass('page-template-home')
		) {
			return;
		}

		if (document.getElementById('leverage') === null) {
			return;
		}

		// Constants
		const margin = { top: 10, right: 10, bottom: 80, left: 80 };
		const widthOuter = 960;
		const heightOuter = 500;
		const width = widthOuter - margin.left - margin.right;
		const height = heightOuter - margin.top - margin.bottom;
		const xColumn = 'whenopen';
		const yColumn = 'profit';

		const defaultCapital = document.getElementById('capital').value;
		const defaultStrategy = document.getElementById('strategy').value;
		const defaultInstrument = document.getElementById('instrument').value;
		const defaultPeriod = document.getElementById('period').value;
		const defaultrisk = document.getElementById('risk').value;

		// SVG
		const svg = d3
			.select('#leverage')
			.append('svg')
			.attr('viewBox', [0, 0, widthOuter, heightOuter]);

		// Tooltip
		const tooltip = d3
			.select('.section--graph')
			.append('div')
			.style('opacity', 0)
			.attr('class', 'tooltip')
			.style('pointer-events', 'none')
			.style('position', 'absolute')
			.style('white-space', 'pre')
			.style('background-color', '#ffffff')
			.style('border', 'solid')
			.style('border-width', '1px')
			.style('padding', '5px');
		const mouseover = function (d) {
			tooltip.style('opacity', 1);
		};
		const mousemove = function (d) {
			tooltip
				.html(
					`<b>${d['whenopen']}</b>
<b>Total</b>: ${d['total']} $
<b>Profit</b>: ${d['profit']} $
<b>Capital</b>: ${d['totalPrev']} $`
				)
				.style('left', d3.event.pageX - 90 + 'px')
				.style('top', d3.event.pageY - 16 + 'px');
		};
		const mouseleave = function (d) {
			tooltip.style('opacity', 0);
		};

		// Canvas
		const canvas = svg
			.append('g')
			.attr('id', 'canvas')
			.attr('width', width)
			.attr('height', height)
			.attr('transform', `translate(${margin.left}, ${margin.top})`);

		// Add X axis
		const x = d3.scaleTime().range([0, width]);
		const xAxisDetails = d3
			.axisBottom(x)
			.tickSizeOuter(0)
			.tickFormat(d3.timeFormat('%d %b %Y %H:%M'));
		const xAxis = canvas
			.append('g')
			.attr('transform', 'translate(0,' + height + ')')
			.attr('class', 'axis axis--x');

		// Add Y axis
		const y = d3.scaleLinear().range([height, 0]);
		const yAxisDetails = d3
			.axisLeft(y)
			.tickSizeOuter(0)
			.tickFormat(function (d, i) {
				return d + ' USD';
			});
		const yAxis = canvas.append('g').attr('class', 'axis axis--y');

		// Add a clipPath: everything out of this area won't be drawn.
		const clip = canvas
			.append('clipPath')
			.attr('id', 'clip')
			.append('rect')
			.attr('width', width)
			.attr('height', height);

		// Zooming rect background
		canvas
			.append('g')
			.attr('id', 'valueLine')
			.append('rect')
			.attr('width', width)
			.attr('height', height)
			.attr('class', 'plot')
			.attr('pointer-events', 'all')
			.attr('fill', 'none');

		// Red background
		const redBackground = canvas
			.select('#valueLine')
			.append('rect')
			.attr('x', 0)
			.attr('class', 'classon')
			.attr('width', width)
			.attr('fill', 'red')
			.attr('opacity', 0.1);

		// Dots
		const valueDotContainer = canvas.select('#valueLine').append('g');

		// RENDER CHART
		renderChart(defaultCapital, defaultStrategy, defaultInstrument, defaultPeriod, defaultrisk);

		// LISTEN TO THE BUTTON -> RENDER IF USER CHANGE IT
		d3.select('#strategy').on('input', function () {
			strategyOptionsChange(this.value);
		});
		d3.select('#capital').on('input', function () {
			const currentStrategy = document.getElementById('strategy').value;
			const currentInstrument = document.getElementById('instrument').value;
			const currentPeriod = document.getElementById('period').value;
			const currentRisk = document.getElementById('risk').value;

			renderChart(
				+this.value,
				currentStrategy,
				currentInstrument,
				currentPeriod,
				currentRisk
			);
		});
		d3.select('#instrument').on('input', function () {
			const currentStrategy = document.getElementById('strategy').value;
			const currentCapital = document.getElementById('capital').value;
			const currentPeriod = document.getElementById('period').value;
			const currentRisk = document.getElementById('risk').value;

			renderChart(+currentCapital, currentStrategy, this.value, currentPeriod, currentRisk);
		});
		d3.select('#period').on('input', function () {
			const currentStrategy = document.getElementById('strategy').value;
			const currentCapital = document.getElementById('capital').value;
			const currentInstrument = document.getElementById('instrument').value;
			const currentRisk = document.getElementById('risk').value;

			renderChart(
				+currentCapital,
				currentStrategy,
				currentInstrument,
				this.value,
				currentRisk
			);
		});
		d3.select('#risk').on('input', function () {
			const currentStrategy = document.getElementById('strategy').value;
			const currentCapital = document.getElementById('capital').value;
			const currentInstrument = document.getElementById('instrument').value;
			const currentPeriod = document.getElementById('period').value;

			renderChart(
				+currentCapital,
				currentStrategy,
				currentInstrument,
				currentPeriod,
				this.value
			);
		});

		function strategyOptionsChange(strategyName) {
			$.ajax({
				type: 'POST',
				url: appData.ajaxUrl + '?action=app_get_indicators',
				data: { strategy: strategyName },
				dataType: 'json'
			}).then((response) => {
				const $instrument = $('#instrument');
				$instrument.empty();

				$.each(response.data.currency_terms_formated, function (key, value) {
					const upperCaseValue = value.toUpperCase();
					$instrument.append(
						$('<option></option>').attr('value', upperCaseValue).text(upperCaseValue)
					);
				});

				const currentCapital = document.getElementById('capital').value;
				const currentInstrument = document.getElementById('instrument').value;
				const currentPeriod = document.getElementById('period').value;
				const currentRisk = document.getElementById('risk').value;

				renderChart(
					currentCapital,
					strategyName,
					currentInstrument,
					currentPeriod,
					currentRisk
				);
			});
		}

		function renderChart(capital, strategy, instrument, period, risk) {
			let temp_capital = capital;
			let tempBenefit = 0;
			let tempDrawdownResult = 0;
			let tempDrawdown = 0;
			let benefitLost = false;

			addLoader();

			d3.csv(
				`${appData.ajaxUrl}?action=app_get_levrage_graph_data&strategy=${strategy}&instrument=${instrument}&period=${period}`,
				function (row_data, row_index, column_names) {
					row_data.totalPrev = temp_capital;

					for (let i = 0; i < column_names.length; i++) {
						if (column_names[i] === yColumn) {
							row_data[column_names[i]] = +row_data[column_names[i]] * risk;
							temp_capital *= 10;
							temp_capital += row_data[column_names[i]] * 10;
							temp_capital /= 10;

							if (temp_capital >= 0 && !benefitLost) {
								tempBenefit = temp_capital;
							} else {
								tempBenefit = 0;
								benefitLost = true;
							}

							if (+row_data[column_names[i]] < 0) {
								tempDrawdown += +row_data[column_names[i]];

								if (tempDrawdown < tempDrawdownResult) {
									tempDrawdownResult = tempDrawdown;
								}
							} else {
								tempDrawdown = 0;
							}
						} else {
							row_data[column_names[i]] = row_data[column_names[i]];
						}
					}

					row_data.total = temp_capital;
					return row_data;
				},
				function (error, data) {
					if (error) throw error;

					// Bind new data
					const valueLine = svg.selectAll('.valueLine').data([data], function (d) {
						return d;
					});
					const valueDot = valueDotContainer.selectAll('circle').data(data);

					// Sort the data
					data.sort(function (a, b) {
						return new Date(b[xColumn]) - new Date(a[xColumn]);
					});

					$('#benefit').val((tempBenefit * 10 - capital * 10) / 10);
					$('#gain').val(
						Math.round(((tempBenefit * 10 - capital * 10) / 10 / capital) * 100)
					);
					$('#drawdown').val(Math.round(tempDrawdownResult));

					const recomendedCap = Math.abs(Math.round(tempDrawdownResult) * 1.5);
					$('#recomended_cap').val(recomendedCap);
					if ($('#capital').val() < recomendedCap) {
						$('#capital').css('background-color', 'rgba(255, 0, 0, 0.1)');
					} else {
						$('#capital').css('background-color', '#ffffff');
					}

					// Update X axis
					if (period != 0) {
						const minDomainX = getMinDomain(period);
						const maxDomainX = new Date().setHours(23, 59, 59, 999);
						x.domain([minDomainX, maxDomainX]);
					} else {
						x.domain(
							d3.extent(
								data.map(function (item) {
									return new Date(item.whenopen);
								})
							)
						);
					}

					xAxis.call(xAxisDetails);
					xAxis
						.selectAll('text')
						.attr('text-anchor', 'end')
						.attr('transform', 'rotate(-20)')
						.attr('font-weight', '700');

					// Update Y axis
					const minDomainY = d3.min(data, function (d) {
						return +d.total - 200;
					});
					const maxDomainY = d3.max(data, function (d) {
						return +d.total + 400;
					});

					y.domain([minDomainY, maxDomainY]);
					yAxis.call(yAxisDetails);

					// valueLine
					valueLine
						.enter()
						.select('#valueLine')
						.attr('clip-path', 'url(#clip)')
						.insert('path', 'rect')
						.attr('class', 'valueLine')
						.merge(valueLine)
						.attr('fill', 'none')
						.attr('stroke', '#725000')
						.attr('stroke-width', 2)
						.attr(
							'd',
							d3
								.line()
								.x(function (d) {
									return x(new Date(d[xColumn]));
								})
								.y(function (d) {
									return y(d.total);
								})
						);
					valueLine.exit().remove();

					// Dots
					valueDot
						.enter()
						.append('circle')
						.attr('r', 3)
						.attr('fill', function (d) {
							if (d.total > 0) {
								return 'green';
							} else {
								return 'red';
							}
						})
						.attr('cx', function (d) {
							return x(new Date(d[xColumn]));
						})
						.attr('cy', function (d) {
							return y(d.total);
						})
						.on('mouseover', mouseover)
						.on('mousemove', mousemove)
						.on('mouseleave', mouseleave);
					valueDot.exit().remove();
					valueDot
						.attr('cx', function (d) {
							return x(new Date(d[xColumn]));
						})
						.attr('cy', function (d) {
							return y(d.total);
						})
						.attr('fill', function (d) {
							if (d.total > 0) {
								return 'green';
							} else {
								return 'red';
							}
						});

					// Background gray lines
					svg.selectAll('.axis--y > g.tick > line')
						.attr('x2', width)
						.style('stroke', 'lightgrey');

					// Red background
					redBackground.attr('y', y(0)).attr('height', Math.abs(minDomainY));

					// Remove loader
					removeLoader();
				}
			);
		}
	}

	function getMinDomain(periodDiff) {
		let tempDate = new Date();
		let tempDateDiff = 0;

		switch (periodDiff) {
			case '7d':
				tempDateDiff = 7;
				break;
			case '14d':
				tempDateDiff = 15;
				break;
			case '1m':
				tempDateDiff = 32;
				break;
			case '2m':
				tempDateDiff = 63;
				break;
			case '3m':
				tempDateDiff = 94;
				break;
			case '4m':
				tempDateDiff = 124;
				break;
			case '1y':
				tempDateDiff = 367;
				break;
			case '2y':
				tempDateDiff = 736;
				break;
			case '3y':
				tempDateDiff = 1101;
				break;
			case '4y':
				tempDateDiff = 1467;
				break;
			case '5y':
				tempDateDiff = 1837;
				break;
			case 'full':
				tempDateDiff = 2202;
				break;

			default:
				tempDateDiff = 6;
				break;
		}

		let firstDay = new Date(tempDate.getTime() - tempDateDiff * 24 * 60 * 60 * 1000);

		return firstDay;
	}

	function stopFormSubmission() {
		if ($('#ajax-form').length) {
			$('#ajax-form').on('submit', function (e) {
				e.preventDefault();
			});
		}
	}

	function initScrollUpdater() {
		if ($('#risk').length) {
			$('.riskValue').text(+$('#risk').val() / 10);

			$('#risk').on('change', function () {
				$('.riskValue').text(+$(this).val() / 10);
			});
		}
	}

	function fillscrollline() {
		const $scrollline = document.querySelector('.scroll-line');
		if ($scrollline === null) {
			return;
		}

		window.addEventListener('scroll', function () {
			const $windowHeight = window.innerHeight;
			const $fullHeight = document.body.clientHeight;
			const $scrolled = window.scrollY;
			const $percentScrolled = ($scrolled / ($fullHeight - $windowHeight)) * 100;
			$scrollline.style.width = $percentScrolled + '%';
		});
	}

	function checkPayment() {
		$('label input[type=radio], label input[type=checkbox]').click(function () {
			$('label:has(input:checked)').addClass('active');
			$('label:has(input:not(:checked))').removeClass('active');
		});
	}

	function checkOnlyNumbers() {
		$('.card_number').keypress(function (e) {
			if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
				$('.errmsg').html('Number Only').stop().show().fadeOut('slow');
				return false;
			}
		});
		$('.exp').keypress(function (e) {
			if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
				$('.errmsg').html('Number Only').stop().show().fadeOut('slow');
				return false;
			}
		});
		$('.cvv').keypress(function (e) {
			if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
				$('.errmsg').html('Number Only').stop().show().fadeOut('slow');
				return false;
			}
		});
	}

	function giveSpace() {
		document.getElementById('CardNumber').addEventListener('input', function (e) {
			e.target.value = e.target.value
				.replace(/[^\dA-Z]/g, '')
				.replace(/(.{4})/g, '$1 ')
				.trim();
		});
	}

	function addSlash() {
		let $characterCount;
		$('#EXP')
			.on('input', function (e) {
				if ($(this).val().length == 2 && $characterCount < $(this).val().length) {
					$(this).val($(this).val() + '/');
				}
				$characterCount = $(this).val().length;
			})
			.on('keyup', function (e) {
				if (e.which === 8) {
					$(this).val($(this).val().replace(/\/$/, ''));
				}
			});
	}

	function validateEmail() {
		$('#email').keypress(function () {
			const form = document.getElementById('form');
			const email = document.getElementById('email').value;
			const text = document.getElementById('text');
			const pattern = /\w+@\w+\.\w+/g;
			if (email.match(pattern)) {
				form.classList.add('valid');
				form.classList.remove('invalid');
				text.innerHTML = 'Your emailis valid';
				text.style.color = '#00ff00';
			} else {
				form.classList.add('invalid');
				form.classList.remove('valid');
				text.innerHTML = 'Your emailis not valid';
				text.style.color = '#ff0000';
			}
		});
	}

	function changeStep1() {
		$('#signUp').on('click', function (e) {
			e.preventDefault();
			$('#signup2').show();
			$('#signup1').hide();
		});
	}
	function changeStep2() {
		$('#signUp2').on('click', function (e) {
			e.preventDefault();
			$('#signup3').show();
			$('#signup2').hide();
		});
	}

	function ajaxRenderNews() {
		// console.log('ajaxRenderNews function called');
		if (newsLoading) {
			return;
		}

		newsLoading = true;
		let $loadMore = $('.load--more');
		let pageNumUrl = '';

		if (!$loadMore.length) {
			return;
		}


		let pageNum = $loadMore.length ? parseInt($loadMore.attr('data-page-next')) : 1;
		let seedNum = $loadMore.length ? parseInt($loadMore.attr('data-seed-num')) : 1;
		pageNumUrl = $loadMore.length ? '&page_num=' + pageNum + '&seed_num=' + seedNum : '';

		// console.log('Request URL:', appData.ajaxUrl + '?action=app_get_news' + pageNumUrl);

		addLoader();


		$.ajax({
			url: appData.ajaxUrl + '?action=app_get_news' + pageNumUrl
		}).then((response) => {
			newsLoading = false;

			let responseData = response.data;
			let responseJSON = responseData.markup;
			let loadMore = responseData.loadMore;

			if (responseJSON) {
				$('.news').append(responseJSON);
			}

			if (loadMore) {
				if ($loadMore.length) {
					$loadMore.attr('data-page-next', responseData.page_next);
				}
			} else {
				$loadMore.remove();
			}

			removeLoader();
		});
	}

	function handleAjaxLoadMoreNews() {
		// console.log('Checking section-dark presence');
		if (!$('.section-header-news').length) {
			return;
		}

		if (window.innerHeight + window.scrollY + 1000 >= document.body.offsetHeight) {
			console.log('Near bottom of page. Loading more news.');
			ajaxRenderNews();
		}
	}


	function ajaxRenderProjects() {
		// console.log('ajaxRenderNews function called');
		if (projectsLoading) {
			return;
		}

		projectsLoading = true;
		let $loadMore = $('.load--more');
		let pageNumUrl = '';


		if (!$loadMore.length) {
			return;
		}


		let pageNum = $loadMore.length ? parseInt($loadMore.attr('data-page-next')) : 1;
		let seedNum = $loadMore.length ? parseInt($loadMore.attr('data-seed-num')) : 1;
		pageNumUrl = $loadMore.length ? '&page_num=' + pageNum + '&seed_num=' + seedNum : '';

		// console.log('Request URL:', appData.ajaxUrl + '?action=app_get_news' + pageNumUrl);

		addLoader();


		$.ajax({
			url: appData.ajaxUrl + '?action=app_get_projects' + pageNumUrl
		}).then((response) => {
			projectsLoading = false;

			let responseData = response.data;
			let responseJSON = responseData.markup;
			let loadMore = responseData.loadMore;

			if (responseJSON) {
				$('.projects').append(responseJSON);
			}

			if (loadMore) {
				if ($loadMore.length) {
					$loadMore.attr('data-page-next', responseData.page_next);
				}
			} else {
				$loadMore.remove();
			}

			removeLoader();
		});
	}

	function handleAjaxLoadMoreProjects() {
		// console.log('Checking section-dark presence');
		if (!$('.section-dark').length) {
			return;
		}

		if (window.innerHeight + window.scrollY + 1000 >= document.body.offsetHeight) {
			console.log('Near bottom of page. Loading more news.');
			ajaxRenderProjects();
		}
	}

})(jQuery);
